import { createReactLifecycles } from "single-spa-workfront";
import { App } from './App';

const lifecycles = createReactLifecycles({
    suppressComponentDidCatchWarning: true,
    rootComponent: App,
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
